.hover-icon-wrapper .hover-icon {
    font-size: 20px;
    transition: transform 0.2s;
}
.hover-icon-wrapper:hover .default-icon {
    display: none;
}
.hover-icon-wrapper .hovered-icon {
    display: none;
    font-size: 20px;
    transition: transform 0.2s;
}
.hover-icon-wrapper:hover .hovered-icon {
    display: inline-block;
}